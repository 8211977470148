import { ValidationProvider } from 'vee-validate';
import { extend } from 'vee-validate';

extend("alphanumeric", {
  message: (field,) => {      
    return 'The ' + field + ' field must include only alphanumeric characters.'
    },

  validate: (value) => {
      if(!value) {return false;}
      
      let count = (value.match(/^[0-9A-Za-z]+$/));
      return !!count;
  }
});

extend('dateGreater', {
    params: ['target'],
    validate(value, { target }) {
        return !target || new Date(value) > new Date(target);
    },
    message: '{_field_} should be after {target}'
})

extend('dateGreaterOrEqual', {
    params: ['target'],
    validate(value, { target }) {
        return dateGreaterOrEqualTest(value, target);
    },
    message: '{_field_} should be the same as or after {target}'
});
export function dateGreaterOrEqualTest(value, target) {   
  return !target || new Date(value) >= new Date(target);
}

extend('dateLessThanOrEqual', {
    params: ['target'],
    validate(value, { target }) {
        return !target || new Date(value) <= new Date(target);
    },
    message: '{_field_} should be before {target}'
})

extend('dateLessThan', {
  params: ['target'],
  validate(value, { target }) {
      return !target || new Date(value) < new Date(target);
  },
  message: '{_field_} should be before {target}'
})

extend('qtyGreater', {
    params: ['target'],
    validate(value, { target }) {
        return !target ||  value >  target;
    },
    message: '{_field_} should be  after {target}'
});

extend('isbnValid',{
    params: ['isValid'],
    validate(value, { isValid }){
        return value && isValid;
    },
    message: '{_field_} format is invalid'
});

extend('zeroThroughNineOrX',{
    validate(value){
        var numbersOrX = new RegExp(
            /^([0-9-]{10,}X|[0-9-]{10,}|978[0-9-]{10,}|979[0-9-]{10,})$/
        );

        return value && numbersOrX.test(value);
    }
});

extend('wholesaleCost', {
    params: ['buyersGuideCost', 'isBuyersGuide'],
    validate(value, { buyersGuideCost, isBuyersGuide }){
        let cost = parseFloat(buyersGuideCost);
        if(isBuyersGuide && value > cost)
        {
            return false;
        }
        return true;
    },
    message: '{_field_} must be equal to or below ${buyersGuideCost}'
});

extend("p-integer", {
    message: (field, placeholders) => {
      let value = parseFloat(placeholders._value_);
      if(value > 2147483647){
        return 'The ' + field + ' field exceeds the maximum allowed.';
      }

      if(value < -2147483648){
        return 'The ' + field + ' field exceeds the minimum allowed.';
      }
      return 'The ' + field + ' field must be an integer.';
    },
    validate: (value) => {
      value = parseFloat(value);
      return !isNaN(value)
              && isFinite(value)
              && Number.isInteger(value)
              && value <= 2147483647
              && value >= -2147483648;
    }
})

extend('required', {
  validate (value) {
    return {
      required: true,
      valid: ['', null, undefined].indexOf(value) === -1
    };
  },
  computesRequired: true
});

extend("p-decimal", {
    message: (field, placeholders) => {
      let value = Math.abs(placeholders._value_) + '';
      let leftHand = value;
      let rightHand = '';
      if(value.indexOf('.') > -1){
        var splitValue = value.split('.')
        leftHand = splitValue[0]
        rightHand = splitValue[1];
      }

      if(leftHand.length > (placeholders.precision - placeholders.scale)){
        return 'The ' + field + ' field allows maximum of ' + (placeholders.precision - placeholders.scale)  + ' digits to the left of the decimal.';
      }
      if(rightHand.length > placeholders.scale){
        return 'The ' + field + ' field allows maximum of ' + placeholders.scale  + ' digits to the right of the decimal.';
      }
      return 'The ' + field + ' field must be a decimal.'},

    validate: (value, args) => {
      return pDecimalValidationTest(value, args);
    },
    params: ['precision','scale']
});

export function pDecimalValidationTest(value, args) {
  let regex = new RegExp('^\\d{0,' + (args.precision - args.scale) + '}(\\.\\d{0,' + args.scale + '})?$');
  return !isNaN(parseFloat(value))
          && isFinite(value)
          && regex.test(Math.abs(value));
}

extend("decimal", {
  message: field => 'The ' + field + ' field must be a decimal.',
  validate: (value) => {
    return !isNaN(parseFloat(value)) && isFinite(value);
  }
})

extend("exceedQty", {
    params: ['totalQty','compareQty'],
    validate: (value, {totalQty, compareQty}) => {
        return parseInt(compareQty) <= parseInt(totalQty);
    },
    message: '{_field_} can\'t exceed the qty of {totalQty}'
})

extend("exceedInvoicedQty", {
    params: ['totalInvoicedQty','totalReturnQty'],
    validate: (value, {totalInvoicedQty, totalReturnQty}) => {
        let sum = parseInt(value) + parseInt(totalReturnQty);
        return sum <= parseInt(totalInvoicedQty);
    },
    message: '{_field_} can\'t exceed the invoiced qty of {totalInvoicedQty}'
})

extend("exceedReturnedQty", {
    params: ['returnQty', 'summedMemoQty'],
    validate: (value, {returnQty, summedMemoQty}) => {
        return parseInt(summedMemoQty) <= parseInt(returnQty);
    },
    message: 'Resolved + Cancelled + Denied Qty\'s can\'t exceed the Returned Qty of {returnQty}'
})

extend("exceedOrderQty", {
    params: ['orderQty', 'summedQty'],
    validate: (value, {orderQty, summedQty}) => {
        return parseInt(summedQty) <= parseInt(orderQty);
    },
    message: 'Shipped + Cancelled + Back Ordered Qty\'s can\'t exceed the Order Qty of {orderQty}'
})

extend("exceedMemoAmount", {
    params: ['memoAmount', 'summedResolvedAmount'],
    validate: (value, {memoAmount, summedResolvedAmount}) => {
        return parseInt(summedResolvedAmount) <= parseInt(memoAmount);
    },

    message: (field, placeholders) => {
      //todo: use the currency formatter to format the memo amount.
      //may need to change the rules to an object instead of a string and pass in the formatter
      //similar to some of the import configurations.
      return `Total resolved amount cannot exceed memo amount of $${placeholders.memoAmount}`
    }
})

extend("isUnique", {
    params: ['isUniqueValue', 'message'],
    validate: (value, {isUniqueValue}) => {
        return isUniqueValue;
    },
    message: '{message}'
})

import uniqueid from 'lodash.uniqueid'

export default {

  components: {
    ValidationProvider
  },
  props: {
    rules: {
      default: ''
    },
    vid: {
      type: String,
      default () { return uniqueid('input_'); } //vid must be unique on a page for validation to work properly
    },
    mode: {
      type: String,
      default: undefined
    }
  },
  computed: {
    isRequired() {
      return this.rules && (typeof this.rules === "string" && this.rules.includes("required")) || (typeof this.rules === "object" && this.rules.isRequired);
    },
    labelText() {
        if (this.label) {
            return this.label + (this.isRequired ? " *" : "");
        }
        return null;
    }
  }
};