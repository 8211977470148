import uniqueid from 'lodash.uniqueid';

export default {

  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default () { return uniqueid(`baseInput_${this.label.replace(/[^\w]/gi, '')}_`); } 
    },
    infoMessage: {
      type: String,
      default: null
    },
  },
  computed: {
    labelText() {
      return this.label;
    }
  },
  methods: {
    handleInput(e) {
      if(this.$options.name !== 'p-number') // Filter out emitting events for p-number to avoid doubling the event firing
        this.$emit("input", e);
    }
  }
};